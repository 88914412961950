.app {
  background-color: #151515;
  min-height: 100vh;
  overflow: hidden;
}

img {
  display: block;
}

.MuiButtonBase-root.m {
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  font-family: 'fontBold';
  background-color: transparent;
}

.MuiButtonBase-root.m:disabled {
  cursor: not-allowed;
  pointer-events: auto;
  /* color: rgba(255,255,255); */
  background-color: transparent;
  /* border-bottom: 2px solid; */
  background: linear-gradient(to bottom right, #14FD41, #D8D8D8);
  -webkit-background-clip: text;
  color: transparent;
  /* border-radius: 0; */
}

.MuiButtonBase-root.m:hover {
  background-color: transparent;
}

.boo-store-swiper.swiper {
  overflow: visible;
}

.boo-store-swiper .swiper-slide {
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.9);
  width: 898px;
  height: 366px;
}

.boo-store-swiper .swiper-slide-active,.swiper-slide-duplicate-active{
  transform: scale(1);
}

/* .boo-store-swiper .swiper-slide {
  width: 44%;
} */

.boo-store-swiper .swiper-pagination {
  position: absolute;
  bottom: -28px !important;
  z-index: 10;
}

.boo-store-swiper .swiper-pagination-bullet {
  /* display: inline-block;
  width: 12px;
  height: 12px; */
  background-color: #A6A6A6;
  /* border-radius: 100px;
  margin: 0 8px;
  cursor: pointer; */
}

.boo-store-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #20FB4B;
}

.swiper.project-item-swiper {
  height: 570px;
}

.project-item-swiper .swiper-slide {
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.accountButton.MuiIconButton-root {
  padding: 0;
  padding-left: 12px;
}

.accountButton.MuiIconButton-root svg {
  width: 40px;
  height: 40px;
}

html body .app .marketplace-select .MuiSelect-select {
  padding-left: 24px;
  padding-right: 48px;
}

html body .app .marketplace-select .MuiSelect-select:hover {
  padding-left: 24px;
  padding-right: 21px;
  border-color: #14FD41;
}

html body .app .MuiOutlinedInput-notchedOutline {
  border: none;
}

.marketplace-select .MuiSvgIcon-root {
  color: #FFFFFF;
  width: 42px;
  height: 42px;
  top: 9px;
}

.sort-select-menu .MuiPaper-root, .status-select-menu .MuiPaper-root {
  background-color: #272727;
  margin-top: 10px;
}

.sort-select-menu .MuiList-root, .status-select-menu .MuiList-root {
  padding: 0;
}

.sort-select-menu .MuiList-root .MuiMenuItem-root.Mui-selected, .status-select-menu .MuiList-root .MuiMenuItem-root.Mui-selected {
  background-color: rgba(0, 0, 0, 0.3);
}

.sort-select-menu .MuiList-root .MuiMenuItem-root:hover, .status-select-menu .MuiList-root .MuiMenuItem-root:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

